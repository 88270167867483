import {
    AggregatableObject,
    GtmAnalyticalModel,
    GtmEvoOutputObject,
    GtmHistoryEntry,
    GtmModel,
    GtmProject,
    GtmProjectInput,
    isGtmAnalyticalModel,
} from 'src/gtmProject';
import { useConglomerateActionManager } from 'src/hooks/conglomerate/useConglomerateActionManager';
import { useLazyFetchGtmProjectFile } from 'src/hooks/evoContext/useLazyFetchGtmProjectFile';
import { skipHistoryEntry, useProjectSynchronizer } from 'src/hooks/project/useProjectSynchronizer';
import {
    deselectCurrentModelSelectedObject,
    overwriteProject,
    setCurrentModelAggregateObjectAsSelected,
    setCurrentModelSelectedObjectIndex,
    setSelectedModelIndex,
} from 'src/store/project/projectSlice';
import {
    selectCurrentModel,
    selectCurrentModelSelectedObject,
    selectCurrentProjectData,
    selectCurrentProjectRedoEntries,
    selectCurrentProjectUndoEntries,
    selectCurrentProjectVersionId,
} from 'src/store/project/selectors';
import { useAppDispatch, useAppSelector } from 'src/store/store';

export function useHistoryManager() {
    const [FetchGtmProjectFileTrigger] = useLazyFetchGtmProjectFile();
    const dispatch = useAppDispatch();
    const currentProject = useAppSelector(selectCurrentProjectData);
    const currentModel = useAppSelector(selectCurrentModel);
    const currentModelSelectedObject = useAppSelector(selectCurrentModelSelectedObject);
    const projectUndoEntries = useAppSelector(selectCurrentProjectUndoEntries);
    const projectRedoEntries = useAppSelector(selectCurrentProjectRedoEntries);
    const currentProjectVersionId = useAppSelector(selectCurrentProjectVersionId);
    const { syncProject } = useProjectSynchronizer();
    const { refreshVisualizationAndIssues } = useConglomerateActionManager();

    async function undoOperation() {
        const lastUndoEntry = projectUndoEntries.at(-1);
        if (!lastUndoEntry) {
            return;
        }
        const [project] =
            (await FetchGtmProjectFileTrigger(currentProject.name, lastUndoEntry.versionId)) ?? [];
        if (!project) {
            return;
        }
        const updatedUndoEntries = projectUndoEntries.slice(0, -1);
        const updatedRedoEntries = [
            ...projectRedoEntries,
            { ...lastUndoEntry, versionId: currentProjectVersionId },
        ];
        const modifiedProject = {
            ...project,
            history: { undoEntries: updatedUndoEntries, redoEntries: updatedRedoEntries },
        };
        dispatch(overwriteProject({ project: modifiedProject }));
        updateModelSelectionsAndVisualization(currentModel, currentModelSelectedObject, project);
        syncProject(skipHistoryEntry);
    }

    async function redoOperation() {
        const lastRedoEntry = projectRedoEntries.at(-1);
        if (!lastRedoEntry) {
            return;
        }
        const [project] =
            (await FetchGtmProjectFileTrigger(currentProject.name, lastRedoEntry.versionId)) ?? [];
        if (!project) {
            return;
        }

        const updatedRedoEntries = projectRedoEntries.slice(0, -1);
        const updatedUndoEntries = [
            ...projectUndoEntries,
            { ...lastRedoEntry, versionId: currentProjectVersionId },
        ];
        const modifiedProject = {
            ...project,
            history: { undoEntries: updatedUndoEntries, redoEntries: updatedRedoEntries },
        };
        dispatch(overwriteProject({ project: modifiedProject }));
        updateModelSelectionsAndVisualization(currentModel, currentModelSelectedObject, project);
        syncProject(skipHistoryEntry);
    }

    async function rollbackToVersion(versionId: string) {
        const matchingIndex = projectUndoEntries.findIndex(
            (entry) => entry.versionId === versionId,
        );
        if (matchingIndex === -1) {
            return;
        }
        const [project] = (await FetchGtmProjectFileTrigger(currentProject.name, versionId)) ?? [];
        if (!project) {
            return;
        }

        const rolledBackUndoEntries = projectUndoEntries.slice(matchingIndex);
        let updatedRedoEntries: GtmHistoryEntry[] = [];

        for (let i = 0; i < rolledBackUndoEntries.length - 1; i += 1) {
            updatedRedoEntries.unshift({
                ...rolledBackUndoEntries[i],
                versionId: rolledBackUndoEntries[i + 1].versionId,
            });
        }
        updatedRedoEntries.unshift({
            ...(rolledBackUndoEntries.at(-1) as GtmHistoryEntry),
            versionId: currentProjectVersionId,
        });
        updatedRedoEntries = [...projectRedoEntries, ...updatedRedoEntries];

        const updatedUndoEntries = projectUndoEntries.slice(0, matchingIndex);
        const modifiedProject = {
            ...project,
            history: { undoEntries: updatedUndoEntries, redoEntries: updatedRedoEntries },
        };
        dispatch(overwriteProject({ project: modifiedProject }));
        updateModelSelectionsAndVisualization(currentModel, currentModelSelectedObject, project);
        syncProject(skipHistoryEntry);
    }

    /**
     * Helper function to make sure the visualization and model and object selections before and after a redo/undo/rollback operation are consistent
     * by trying to keep the current selections as long as the updated state contains matching a matching model and object
     */
    function updateModelSelectionsAndVisualization(
        previousSelectedModel: GtmModel | GtmAnalyticalModel | undefined,
        previousSelectedModelObject:
            | GtmProjectInput
            | AggregatableObject
            | GtmEvoOutputObject
            | undefined,
        newProject: GtmProject,
    ) {
        if (!previousSelectedModel) {
            dispatch(setSelectedModelIndex(0));
            dispatch(deselectCurrentModelSelectedObject());
            refreshVisualizationAndIssues(newProject.models.at(0) as GtmModel);
            return;
        }

        // Update the model selection
        const matchingModelIndex = newProject.models.findIndex(
            ({ id }) => previousSelectedModel.id === id,
        );
        if (matchingModelIndex === -1) {
            dispatch(setSelectedModelIndex(0));
            dispatch(deselectCurrentModelSelectedObject());
            refreshVisualizationAndIssues(newProject.models.at(0) as GtmModel);
            return;
        }

        const matchingModel = newProject.models.at(matchingModelIndex)!;
        dispatch(setSelectedModelIndex(matchingModelIndex));
        refreshVisualizationAndIssues(matchingModel);

        if (!previousSelectedModelObject) {
            dispatch(deselectCurrentModelSelectedObject());
            return;
        }

        // Update the object selection
        if (!isGtmAnalyticalModel(matchingModel)) {
            const matchingObjectIndex = matchingModel.inputObjects?.findIndex(
                ({ id }) => previousSelectedModelObject.id === id,
            );
            if (matchingObjectIndex) {
                dispatch(setCurrentModelSelectedObjectIndex(matchingObjectIndex));
            } else {
                dispatch(deselectCurrentModelSelectedObject());
            }
        } else if (previousSelectedModelObject.id === matchingModel.aggregateGeometry.id) {
            dispatch(setCurrentModelAggregateObjectAsSelected());
        } else {
            const matchingObjectIndex = matchingModel.objects.findIndex(
                ({ id }) => previousSelectedModelObject.id === id,
            );
            dispatch(setCurrentModelSelectedObjectIndex(matchingObjectIndex));
        }
    }

    return {
        undoOperation,
        redoOperation,
        rollbackToVersion,
    };
}

import { WDSThemeProvider } from '@local/web-design-system-2';
import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import { useBaseXyz } from '@local/webviz/dist/context/hooks/useBaseXyz';
import { CameraState, Plane } from '@local/webviz/dist/types/xyz';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { WDS2ThemeContext } from 'src/context/ThemeContext/ThemeContext';
import { useSceneObjectDataManager } from 'src/hooks';
import {
    useBoundaryCreationManager,
    BoundaryCreationState,
} from 'src/hooks/boundaryCreation/useBoundaryCreationManager';
import {
    selectCurrentModelInputObjects,
    selectCurrentModelSettings,
    selectCurrentProjectModelNames,
} from 'src/store/project/selectors';
import { useAppSelector } from 'src/store/store';
import {
    BoundaryCreationState as BoundaryCreationInteractionState,
    selectBoundaryCreationState,
    setBoundaryCreationState,
} from 'src/store/ui/projectPanel';
import { CANCEL_LABEL } from 'src/strings';
import { DEFAULT_PANEL_WIDTH_PX } from 'src/styles';
import { ObjectId, ObjectIdWithVersion } from 'src/types/core.types';
import { boundingBoxToGtmBounds } from 'src/utils/typeTransformations';
import { BoundingBox, getBoundingBoxSnapshot } from 'src/utils/xyzUtils';
import { CoordinatesInput } from 'src/visualization/Common/CoordinatesInput';
import { CoordinatesDisabledState } from 'src/visualization/Common/CoordinatesInput.types';

import { StaticProjectName } from '../ProjectPanel/components/StaticProjectName';
import {
    BOUNDING_BOX_NO_BOUNDS_TITLE,
    BOUNDING_BOX_TITLE,
    NAME_TITLE,
    BOUNDING_BOX_DEFAULT_NAME,
    MAX_TITLE,
    MIN_TITLE,
    ACCEPT,
    ERROR_MAX_MUST_GREATER_THAN_MIN,
    ERROR_DUPLICATE_NAME,
    ENCLOSE_ENTIRE_MODEL,
    BOUNDING_BOX_PADDING,
    REDRAW_LABEL,
} from './BoundaryCreationDialog.constants';
import {
    BoundaryCreationDialogErrorState,
    BoundaryCreationDialogProps,
} from './BoundaryCreationDialog.types';
import { BoundaryCreationModal } from './BoundaryCreationModal';
import { BoundaryDrawRectangleToolbar } from './BoundaryCreationToolbar';

const BOUNDARY_CREATION_VIEW_ID = 'boundary-creation';
const DOWN_ROTATION = [0, 0, 0];

const defaultErrorState: BoundaryCreationDialogErrorState = {
    minErrorState: {
        isErrorX: false,
        isErrorY: false,
        isErrorZ: false,
    },
    maxErrorState: {
        isErrorX: false,
        isErrorY: false,
        isErrorZ: false,
    },
};

function isBoundingBoxError(errorState: BoundaryCreationDialogErrorState): boolean {
    const { minErrorState, maxErrorState } = errorState;
    return (
        minErrorState.isErrorX ||
        minErrorState.isErrorY ||
        minErrorState.isErrorZ ||
        maxErrorState.isErrorX ||
        maxErrorState.isErrorY ||
        maxErrorState.isErrorZ
    );
}

function detectBoundingBoxError(boundingBox: BoundingBox): BoundaryCreationDialogErrorState {
    const { xMin, yMin, zMin, xMax, yMax, zMax } = boundingBox;

    const minErrorState = {
        isErrorX: !Number.isFinite(xMin) || xMax <= xMin,
        isErrorY: !Number.isFinite(yMin) || yMax <= yMin,
        isErrorZ: !Number.isFinite(zMin) || zMax <= zMin,
    };
    const maxErrorState = {
        isErrorX: !Number.isFinite(xMax) || xMax <= xMin,
        isErrorY: !Number.isFinite(yMax) || yMax <= yMin,
        isErrorZ: !Number.isFinite(zMax) || zMax <= zMin,
    };
    return {
        minErrorState,
        maxErrorState,
    };
}

export function BoundaryCreationDialog({ onClose, sx }: Readonly<BoundaryCreationDialogProps>) {
    const { theme: appTheme } = useContext(WDS2ThemeContext);
    const applyTrace = useTrace('boundary-creation-panel');
    const dispatch = useDispatch();

    const currentModelNames = useAppSelector(selectCurrentProjectModelNames);
    const modelObjects = useAppSelector(selectCurrentModelInputObjects);
    const modelSettings = useAppSelector(selectCurrentModelSettings);
    const boundaryCreationState = useAppSelector(selectBoundaryCreationState);

    const { getSceneObjectsBoundingBox } = useSceneObjectDataManager();

    const {
        setXyzStateDirectly,
        removeViewsFromPlotDirectly,
        addViewToPlotDirectly,
        getRectangleTool,
        setStateFromSnapshot,
        getEntityState,
    } = useBaseXyz();
    const {
        createNewAnalyticalModelInProject,
        boundaryCreationStatus,
        resetBoundaryCreationStatus,
    } = useBoundaryCreationManager();

    const [boundaryName, setBoundaryName] = useState<string>(BOUNDING_BOX_DEFAULT_NAME);
    const [boundaryExtents, setBoundaryExtents] = useState<BoundingBox | undefined>(undefined);
    const [isEntireDomainBounded, setIsEntireDomainBounded] = useState<boolean>(false);
    const [errorState, setErrorState] =
        useState<BoundaryCreationDialogErrorState>(defaultErrorState);
    const initialCameraStateRef = React.useRef<CameraState>(
        getEntityState('camera') as CameraState,
    );
    const rectangleTool = getRectangleTool();

    const updateboundaryExtentsIfItExists = useCallback(
        (updatedValue: Partial<BoundingBox>) => {
            if (boundaryExtents && updatedValue) {
                const updatedBoundary = { ...boundaryExtents, ...updatedValue };
                const newErrorState = detectBoundingBoxError(updatedBoundary);
                setErrorState(newErrorState);
                setBoundaryExtents((currentBoundary) => ({
                    ...currentBoundary!,
                    ...updatedValue,
                }));
            }
        },
        [boundaryExtents],
    );

    function discardCurrentBoundaryExtents() {
        removeViewsFromPlotDirectly([BOUNDARY_CREATION_VIEW_ID]);
        setBoundaryExtents(undefined);
    }

    const restoreCameraState = useCallback(() => {
        // Azimuth and plunge are read-only. This avoids a bunch of warnings in
        // the console.
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { azimuth, plunge, ...restCameraState } = initialCameraStateRef.current;
        setStateFromSnapshot({ camera: restCameraState }, { tween: { duration: 1000 } });
    }, [initialCameraStateRef]);

    const closeDialog = useCallback(() => {
        discardCurrentBoundaryExtents();
        rectangleTool.disable();
        restoreCameraState();
        onClose();
    }, [rectangleTool, onClose, restoreCameraState]);

    async function handleCreateBoundary() {
        await createNewAnalyticalModelInProject(
            modelObjects,
            modelSettings!,
            uuidv4() as ObjectId,
            boundaryName,
            boundingBoxToGtmBounds(boundaryExtents!),
        );
    }

    const handleRectangleDrawn = useCallback(
        (valid: boolean) => {
            if (valid) {
                // Find the boundary min/max z-values
                // This assumes a top-down view
                const sceneBoundingBox = getSceneObjectsBoundingBox(modelObjects);
                if (sceneBoundingBox) {
                    const { zMax, zMin } = sceneBoundingBox;
                    const totalHeight = zMax - zMin;
                    const padding = totalHeight * BOUNDING_BOX_PADDING;
                    // Project the rectangle onto the zMax plane
                    // and then extrude that down to the zMin plane.
                    // This should help avoid issues with the bounding box touching
                    // the zMin/zMax of the model exactly
                    const plane: Plane = {
                        normal: [0, 0, 1],
                        point: [0, 0, zMax + padding],
                    };
                    const unprojectedRectangle = rectangleTool.unprojectOntoPlane(plane);
                    if (
                        unprojectedRectangle &&
                        // We only care about the topLeft and bottomRight...
                        unprojectedRectangle.topLeft &&
                        unprojectedRectangle.bottomRight
                    ) {
                        const { topLeft, bottomRight } = unprojectedRectangle;
                        const boundingBox: BoundingBox = {
                            xMin: Math.min(topLeft[0], bottomRight[0]),
                            xMax: Math.max(topLeft[0], bottomRight[0]),
                            yMin: Math.min(topLeft[1], bottomRight[1]),
                            yMax: Math.max(topLeft[1], bottomRight[1]),
                            zMin: zMin - padding,
                            zMax: zMax + padding,
                        };
                        setBoundaryExtents(boundingBox);
                        dispatch(
                            setBoundaryCreationState(
                                BoundaryCreationInteractionState.EnterProperties,
                            ),
                        );
                        restoreCameraState();
                    }
                }
            }
        },
        [restoreCameraState, modelObjects, rectangleTool],
    );

    const enableRectangleToolAndTween = useCallback(() => {
        rectangleTool.enable(handleRectangleDrawn);
        initialCameraStateRef.current = getEntityState('camera') as CameraState;
        setStateFromSnapshot(
            { camera: { rotation: DOWN_ROTATION } },
            { tween: { duration: 1000 } },
        );
    }, [rectangleTool, initialCameraStateRef]);

    const handleRedrawBoundary = useCallback(() => {
        discardCurrentBoundaryExtents();
        enableRectangleToolAndTween();
        dispatch(setBoundaryCreationState(BoundaryCreationInteractionState.DrawRectangle));
    }, [enableRectangleToolAndTween]);

    useEffect(() => {
        if (boundaryExtents) {
            setXyzStateDirectly(getBoundingBoxSnapshot(boundaryExtents, BOUNDARY_CREATION_VIEW_ID));
            addViewToPlotDirectly(BOUNDARY_CREATION_VIEW_ID);
        }
    }, [boundaryExtents]);

    useEffect(() => {
        if (boundaryCreationStatus === BoundaryCreationState.SUCCESS) {
            closeDialog();
            resetBoundaryCreationStatus();
        }
    }, [boundaryCreationStatus]);

    useEffect(() => {
        if (!boundaryExtents) {
            enableRectangleToolAndTween();
        }
    }, []);

    const projectNameExists = currentModelNames
        .map((name) => name.toLowerCase())
        .includes(boundaryName.toLowerCase());
    const boundaryCreationStatusIsInProgress = boundaryCreationStatus !== undefined;
    const boundaryNameIsInvalid = projectNameExists && !boundaryCreationStatusIsInProgress;
    const isBoundingError = isBoundingBoxError(errorState);
    const title = boundaryExtents ? BOUNDING_BOX_TITLE : BOUNDING_BOX_NO_BOUNDS_TITLE;

    if (boundaryCreationState === BoundaryCreationInteractionState.DrawRectangle) {
        return <BoundaryDrawRectangleToolbar onCancel={closeDialog} />;
    }
    return (
        <Box sx={sx}>
            <WDSThemeProvider themeMode={appTheme}>
                <Paper sx={{ width: DEFAULT_PANEL_WIDTH_PX }} elevation={4}>
                    <StaticProjectName />
                    <Divider />
                    <Box p={2} paddingTop={1}>
                        <Stack automation-id={applyTrace()}>
                            <DialogHeader title={title} closeDialog={closeDialog} />

                            {isBoundingError && boundaryExtents && (
                                <Alert
                                    severity="error"
                                    sx={{
                                        marginBottom: (theme) => theme.spacing(2),
                                        marginTop: (theme) => theme.spacing(1),
                                    }}
                                >
                                    {ERROR_MAX_MUST_GREATER_THAN_MIN}
                                </Alert>
                            )}

                            {boundaryExtents && (
                                <>
                                    <BoundaryNameSection
                                        isCurrentTextValid={!boundaryNameIsInvalid}
                                        onChange={(event) => setBoundaryName(event.target.value)}
                                    />
                                    <EncloseModelCheckbox
                                        objects={modelObjects}
                                        onChange={updateboundaryExtentsIfItExists}
                                        isEntireDomainBounded={isEntireDomainBounded}
                                        setIsEntireDomainBounded={setIsEntireDomainBounded}
                                    />
                                    <MinMaxInputSection
                                        boundingBox={boundaryExtents}
                                        onChange={updateboundaryExtentsIfItExists}
                                        boundingErrorState={errorState}
                                        isEntireDomainBounded={isEntireDomainBounded}
                                    />
                                    <ButtonsSection
                                        applyTrace={applyTrace}
                                        isCreateDisabled={projectNameExists || isBoundingError}
                                        onCreateBoundary={handleCreateBoundary}
                                        onDeleteBoundary={closeDialog}
                                        onRedrawBoundary={handleRedrawBoundary}
                                    />
                                </>
                            )}
                            <BoundaryCreationModal
                                state={boundaryCreationStatus}
                                onRetry={handleCreateBoundary}
                                onClose={resetBoundaryCreationStatus}
                            />
                        </Stack>
                    </Box>
                </Paper>
            </WDSThemeProvider>
        </Box>
    );
}

function DialogHeader({ title, closeDialog }: { title: string; closeDialog: () => void }) {
    return (
        <ListItem sx={{ padding: 0 }}>
            <ListItemText
                secondary={title}
                sx={{ textTransform: 'uppercase' }}
                secondaryTypographyProps={{ variant: 'body2' }}
            />
            <IconButton onClick={closeDialog} edge="end">
                <CloseIcon />
            </IconButton>
        </ListItem>
    );
}

function OutlinedTextFieldSizedTextProps(
    themeSpacing: number,
): Pick<TextFieldProps, 'InputProps' | 'InputLabelProps'> {
    return {
        InputProps: {
            sx: {
                fontSize: (theme) => theme.spacing(themeSpacing),
            },
        },
        InputLabelProps: {
            sx: { fontSize: (theme) => theme.spacing(themeSpacing) },
        },
    };
}

function BoundaryNameSection({
    isCurrentTextValid,
    onChange,
}: {
    isCurrentTextValid: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
    return (
        <FormControl>
            <TextField
                size="small"
                label={NAME_TITLE}
                variant="outlined"
                onChange={onChange}
                defaultValue={BOUNDING_BOX_DEFAULT_NAME}
                error={!isCurrentTextValid}
                helperText={isCurrentTextValid ? undefined : ERROR_DUPLICATE_NAME}
                sx={{ marginTop: (theme) => theme.spacing(2), width: '100%' }}
                {...OutlinedTextFieldSizedTextProps(1.75)}
            />
        </FormControl>
    );
}

interface MinMaxInputSectionProps {
    boundingBox: BoundingBox;
    onChange: (updatedValue: Partial<BoundingBox>) => void;
    boundingErrorState: BoundaryCreationDialogErrorState;
    isEntireDomainBounded: boolean;
}

function MinMaxInputSection({
    boundingBox,
    onChange,
    boundingErrorState,
    isEntireDomainBounded,
}: Readonly<MinMaxInputSectionProps>) {
    const disabledState: CoordinatesDisabledState = {
        isXDisabled: isEntireDomainBounded,
        isYDisabled: isEntireDomainBounded,
        isZDisabled: isEntireDomainBounded,
    };

    return (
        <>
            <CoordinatesInput
                label={MIN_TITLE}
                coordinateValues={{ x: boundingBox.xMin, y: boundingBox.yMin, z: boundingBox.zMin }}
                onChange={(axis, updatedValue) => onChange({ [`${axis}Min`]: updatedValue })}
                errorState={boundingErrorState.minErrorState}
                disabledState={disabledState}
            />
            <CoordinatesInput
                label={MAX_TITLE}
                coordinateValues={{ x: boundingBox.xMax, y: boundingBox.yMax, z: boundingBox.zMax }}
                onChange={(axis, updatedValue) => onChange({ [`${axis}Max`]: updatedValue })}
                errorState={boundingErrorState.maxErrorState}
                disabledState={disabledState}
            />
        </>
    );
}

interface ButtonsSectionProps {
    applyTrace: (id: string) => string;
    isCreateDisabled: boolean;
    onCreateBoundary: () => Promise<void>;
    onDeleteBoundary: () => void;
    onRedrawBoundary: () => void;
}

function ButtonsSection({
    applyTrace,
    isCreateDisabled,
    onCreateBoundary,
    onDeleteBoundary,
    onRedrawBoundary,
}: Readonly<ButtonsSectionProps>) {
    return (
        <Stack
            direction="row"
            spacing={1}
            justifyContent="space-between"
            sx={{
                paddingTop: (theme) => theme.spacing(3),
            }}
        >
            <Button
                automation-id={applyTrace('redraw-boundary-button')}
                size="small"
                color="primary"
                variant="outlined"
                onClick={onRedrawBoundary}
            >
                {REDRAW_LABEL}
            </Button>
            <Stack direction="row">
                <Button
                    automation-id={applyTrace('cancel-boundary-button')}
                    size="small"
                    color="primary"
                    variant="text"
                    fullWidth
                    onClick={onDeleteBoundary}
                >
                    {CANCEL_LABEL}
                </Button>
                <Button
                    automation-id={applyTrace('create-boundary-button')}
                    size="small"
                    color="primary"
                    variant="contained"
                    fullWidth
                    onClick={onCreateBoundary}
                    disabled={isCreateDisabled}
                >
                    {ACCEPT}
                </Button>
            </Stack>
        </Stack>
    );
}

interface EncloseModelCheckboxProps {
    objects: ObjectIdWithVersion[];
    onChange: (updatedValue: Partial<BoundingBox>) => void;
    isEntireDomainBounded: boolean;
    setIsEntireDomainBounded: (value: boolean) => void;
}

function EncloseModelCheckbox({
    objects,
    onChange,
    isEntireDomainBounded,
    setIsEntireDomainBounded: setEntireDomainBounded,
}: Readonly<EncloseModelCheckboxProps>) {
    const { getSceneObjectsBoundingBox } = useSceneObjectDataManager();
    const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEntireDomainBounded(event.target.checked);
        if (event.target.checked) {
            const fullyEnclosingExtents = getSceneObjectsBoundingBox(objects);
            if (fullyEnclosingExtents) {
                onChange(fullyEnclosingExtents);
            }
        }
    };

    const checkboxControl = (
        <Checkbox
            color="primary"
            size="medium"
            onChange={onCheckboxChange}
            checked={isEntireDomainBounded}
        />
    );

    return (
        <FormControlLabel
            control={checkboxControl}
            labelPlacement="end"
            label={ENCLOSE_ENTIRE_MODEL}
        />
    );
}

import Box from '@mui/material/Box';
import Button from '@mui/material/Button/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';

import { useSceneObjectDataManager } from 'src/hooks';
import { useProjectSynchronizer } from 'src/hooks/project/useProjectSynchronizer';
import { setShowVolumes, updateVolumeObject } from 'src/store/project/projectSlice';
import {
    selectCurrentModel,
    selectCurrentProjectVolumes,
    selectCurrentShowVolumes,
} from 'src/store/project/selectors';
import { useAppSelector } from 'src/store/store';
import { setShouldShowProjectPanel } from 'src/store/ui/projectPanel';
import { setShouldShowPublishPanel } from 'src/store/ui/publishPanel';
import { useStyles } from 'src/visualization/SettingsPanel/components/ObjectSettingsPanel/components/VolumesPanel/VolumesPanel.styles';

const VOLUMES_LABEL = 'Volumes';
const VOLUMES_TEXT = 'Show volumes created by the aggregate geometry';
const PUBLISH_LABEL = 'Publish';
const getSetShowVolumesDescription = (newShowVolumes: boolean, modelName: string) =>
    `${newShowVolumes ? 'Show' : 'hide'} volumes in "${modelName}"`;

export function VolumesPanel() {
    const { classes } = useStyles();
    const dispatch = useDispatch();
    const { syncProject } = useProjectSynchronizer();
    const { loadGtmObject, removeGtmObject } = useSceneObjectDataManager();
    const showVolumes = useAppSelector(selectCurrentShowVolumes);
    const currentVolumes = useAppSelector(selectCurrentProjectVolumes);
    const currentModel = useAppSelector(selectCurrentModel);

    const handleChange = () => {
        const newShowVolumes = !showVolumes;

        // Add remove volumes from the scene.
        // Note that this component does not update the store because it only controls the
        // visualization of volumes but not their creation and deletion.
        if (newShowVolumes) {
            currentVolumes.forEach((volume) => {
                dispatch(updateVolumeObject([volume.id, { ...volume, visible: true }]));
                loadGtmObject(volume.id, volume.version, volume.name);
            });
        } else {
            currentVolumes.forEach((volume) => {
                dispatch(updateVolumeObject([volume.id, { ...volume, visible: false }]));
                removeGtmObject(volume.id);
            });
        }

        dispatch(setShowVolumes(newShowVolumes));
        syncProject({
            description: getSetShowVolumesDescription(newShowVolumes, currentModel?.name as string),
        });
    };

    const handlePublishOnClick = () => {
        dispatch(setShouldShowPublishPanel(true));
        dispatch(setShouldShowProjectPanel(false));
    };

    return (
        <Box className={classes.root}>
            <Paper elevation={4} className={classes.basePaper}>
                <Stack direction="row">
                    <Stack direction="column">
                        <Typography variant="overline" className={classes.volumesLabel}>
                            {VOLUMES_LABEL}
                        </Typography>
                        <Typography
                            variant="body2"
                            className={classes.volumesText}
                            color="secondary"
                        >
                            {VOLUMES_TEXT}
                        </Typography>
                    </Stack>
                    <Switch size="small" checked={showVolumes} onChange={handleChange} />
                </Stack>
                {currentVolumes.length > 0 && (
                    <Button
                        sx={(theme) => ({ margin: theme.spacing(1, 0), width: '100%' })}
                        variant="contained"
                        size="small"
                        onClick={handlePublishOnClick}
                    >
                        {PUBLISH_LABEL}
                    </Button>
                )}
            </Paper>
        </Box>
    );
}

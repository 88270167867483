import { getCombinedToken as getAccessToken } from '@local/login';
import { SCALEBAR_OFFSET } from '@local/webviz/dist/components/Scalebar';
import { initializeVisualization } from '@local/webviz/dist/context/createXyzInstanceContext';
import { updateGridlines, updateGridXOffset } from '@local/webviz/dist/context/snapshots/camera';
import { TokenProvider, UpdateSnapshot, XyzInstance } from '@local/webviz/dist/types/xyz';
import Grid from '@mui/material/Grid';
import merge from 'lodash-es/merge';
import { useEffect, useState } from 'react';

import { useStyles } from './Plot.styles';
import { PlotProps } from './Plot.types';

function updateSelector() {
    return {
        selector: {
            highlight: {
                opacity: 0.01,
            },
        },
    };
}

export const tokenProvider: TokenProvider = (url: string | undefined) => {
    // TODO: remove this check or handle it better when points are migrated over to visualization service
    if (url && url.includes('/visualization/orgs/')) {
        return getAccessToken()?.access_token;
    }
    return undefined;
};

export function Plot({ onInitialized }: PlotProps) {
    const { classes } = useStyles();
    const [htmlElement, ref] = useState<HTMLElement | null>(null);

    useEffect(() => {
        if (!htmlElement) {
            return undefined;
        }
        let instance: XyzInstance | undefined;
        async function initialize(htmlEl: HTMLElement) {
            const initialSnapshot: UpdateSnapshot = merge(
                {},
                updateSelector(),
                updateGridlines(false),
                updateGridXOffset(SCALEBAR_OFFSET - 2),
            );

            instance = await initializeVisualization(htmlEl, initialSnapshot, tokenProvider);
            if (instance) {
                onInitialized(instance);
            }
        }
        initialize(htmlElement);
        return () => {
            instance?.destroy();
        };
    }, [htmlElement]);

    return (
        <Grid container className={classes.root}>
            <Grid container item ref={ref} className={classes.plot} />
        </Grid>
    );
}

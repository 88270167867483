import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const styles = (theme: Theme) =>
    ({
        settingsSection: {
            padding: theme.spacing(2),
            gap: theme.spacing(1.5),
        },
        propertyInput: {
            height: '36px',
            alignItems: 'center',
        },
        infoButton: {
            marginLeft: theme.spacing(1),
        },
    }) as const;

export const useStyles = makeStyles()(styles);

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { GeoscienceObject } from 'src/types/core.types';

import { EvoState } from './evoSlice.types';
import { initialEvoState } from './selectors';

export const evoSlice = createSlice({
    name: 'evo',
    initialState: initialEvoState,
    reducers: {
        setEvoState(_: EvoState, { payload }: PayloadAction<EvoState>) {
            return payload;
        },
        setWorkspaceName(evoState: EvoState, { payload }: PayloadAction<string | undefined>) {
            return {
                ...evoState,
                workspace: { ...evoState.workspace, name: payload },
            };
        },
        setProjectFiles(evoState: EvoState, { payload }: PayloadAction<string[] | undefined>) {
            return {
                ...evoState,
                workspace: { ...evoState.workspace, projectFiles: payload },
            };
        },
        addToFrontOfProjectFiles(
            evoState: EvoState,
            { payload: newProjectFile }: PayloadAction<string>,
        ) {
            return {
                ...evoState,
                workspace: {
                    ...evoState.workspace,
                    projectFiles: [newProjectFile, ...(evoState.workspace.projectFiles ?? [])],
                },
            };
        },
        removeProjectFile(
            evoState: EvoState,
            { payload: projectFileToRemove }: PayloadAction<string>,
        ) {
            return {
                ...evoState,
                workspace: {
                    ...evoState.workspace,
                    projectFiles: evoState.workspace.projectFiles?.filter(
                        (projectFile) => projectFile !== projectFileToRemove,
                    ),
                },
            };
        },
        setUserTriangleMeshes(
            evoState: EvoState,
            { payload }: PayloadAction<GeoscienceObject[] | undefined>,
        ) {
            return {
                ...evoState,
                workspace: { ...evoState.workspace, userTriangleMeshes: payload },
            };
        },
        addUserTriangleMesh(evoState: EvoState, { payload }: PayloadAction<GeoscienceObject>) {
            return {
                ...evoState,
                workspace: {
                    ...evoState.workspace,
                    userTriangleMeshes: [...(evoState.workspace.userTriangleMeshes ?? []), payload],
                },
            };
        },
    },
});

export const {
    setEvoState,
    setWorkspaceName,
    setProjectFiles,
    addToFrontOfProjectFiles,
    removeProjectFile,
    setUserTriangleMeshes,
    addUserTriangleMesh,
} = evoSlice.actions;

import { WDSThemeProvider } from '@local/web-design-system-2';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { SxProps, Theme } from '@mui/material/styles';
import { useContext } from 'react';

import { WDS2ThemeContext } from 'src/context/ThemeContext/ThemeContext';
import { useAppSelector } from 'src/store/store';
import {
    selectShouldMinimizeProjectPanel,
    selectShouldShowProjectPanel,
    selectIsCreatingBoundary,
} from 'src/store/ui/projectPanel';
import { DEFAULT_PANEL_WIDTH_PX } from 'src/styles';
import { ModelsPanel } from 'src/visualization/ProjectPanel/components/ModelsPanel';
import { ObjectsPanel } from 'src/visualization/ProjectPanel/components/ObjectsPanel';
import { ProjectSelector } from 'src/visualization/ProjectPanel/components/ProjectSelector';
import { UrlListener } from 'src/visualization/ProjectPanel/components/UrlListener';

import { NoProjectsDialog } from './components/NoProjectsDialog';

interface ProjectPanelProps {
    sx?: SxProps<Theme>;
}

export function ProjectPanel({ sx }: ProjectPanelProps) {
    const { theme: appTheme } = useContext(WDS2ThemeContext);
    const shouldMinimizeProjectPanel = useAppSelector(selectShouldMinimizeProjectPanel);
    const shouldShowProjectPanel = useAppSelector(selectShouldShowProjectPanel);
    const shouldShowBoundaryDialog = useAppSelector(selectIsCreatingBoundary);

    return (
        <Box sx={sx}>
            <WDSThemeProvider themeMode={appTheme}>
                <UrlListener />
                {shouldShowProjectPanel && (
                    <Paper sx={{ width: DEFAULT_PANEL_WIDTH_PX }} elevation={4}>
                        <ProjectSelector />
                        {!shouldMinimizeProjectPanel && (
                            <>
                                <Divider />
                                {!shouldShowBoundaryDialog && (
                                    <>
                                        <ModelsPanel />
                                        <ObjectsPanel />
                                    </>
                                )}
                            </>
                        )}
                    </Paper>
                )}
                <NoProjectsDialog />
            </WDSThemeProvider>
        </Box>
    );
}

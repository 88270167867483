import { WDSThemeProvider } from '@local/web-design-system-2';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { SxProps, Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { ChangeEvent, useContext } from 'react';

import { GtmMeshTransformationAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { DESIGN_GEOMETRY_SCHEMA } from 'src/constants';
import { WDS2ThemeContext } from 'src/context/ThemeContext/ThemeContext';
import {
    ShouldRenderUpdatedObjects,
    ShouldRunDetectorsOnUpdatedObjects,
    TransformationStatus,
    useTransformationManager,
} from 'src/hooks/transformation/useTransformationManager';
import { selectWorkspaceName } from 'src/store/evo/selectors';
import { addOrReplaceParameterizedGeometryEntryByName } from 'src/store/project/projectSlice';
import {
    selectCurrentAggregateGeometry,
    selectCurrentModel,
    selectCurrentProjectName,
    selectCurrentProjectVolumes,
} from 'src/store/project/selectors';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { setShouldShowProjectPanel } from 'src/store/ui/projectPanel';
import {
    selectModelExportName,
    setModelExportName,
    setShouldShowPublishPanel,
} from 'src/store/ui/publishPanel';
import { CANCEL_LABEL } from 'src/strings';
import { DEFAULT_LIST_MAX_HEIGHT_PX, DEFAULT_PANEL_WIDTH } from 'src/styles';
import { decorateNewObject } from 'src/utils/decorateObject';
import { assert } from 'src/utils/gtmAssert';
import {
    MODEL_SECTION_TITLE,
    PUBLISH_PANEL_TITLE,
    PUBLISH_LABEL,
    PUBLISHING_MESSAGE,
    PUBLISHING_SUCCESS_MESSAGE,
    PUBLISHING_FAILURE_MESSAGE,
} from 'src/visualization/SettingsPanel/components/PublishPanel/PublishPanel.constants';
import { TransformationProgressModal } from 'src/visualization/TransformationProgressModal/TransformationProgressModal';

const titlesByStatus = new Map<TransformationStatus, string>([
    [TransformationStatus.Transforming, PUBLISHING_MESSAGE],
    [TransformationStatus.Uploading, PUBLISHING_MESSAGE],
    [TransformationStatus.Complete, PUBLISHING_SUCCESS_MESSAGE],
    [TransformationStatus.Failed, PUBLISHING_FAILURE_MESSAGE],
]);
const percentagesByStatus = new Map<TransformationStatus, number>([
    [TransformationStatus.Transforming, 0],
    [TransformationStatus.Uploading, 75],
    [TransformationStatus.Complete, 100],
    [TransformationStatus.Failed, 100],
]);

interface PublishPanelProps {
    sx?: SxProps<Theme>;
}

export const PublishPanel = ({ sx }: PublishPanelProps) => {
    const dispatch = useAppDispatch();
    const { theme: appTheme } = useContext(WDS2ThemeContext);
    const workspaceName = useAppSelector(selectWorkspaceName);
    const modelExportName = useAppSelector(selectModelExportName);
    const aggregateGeometryObject = useAppSelector(selectCurrentAggregateGeometry);
    const currentProjectName = useAppSelector(selectCurrentProjectName);
    const currentModel = useAppSelector(selectCurrentModel);
    assert(currentModel !== undefined && currentModel !== null, 'Current model is not defined');
    assert(
        currentModel!.settings !== undefined && currentModel!.settings !== null,
        'Current model settings are not defined',
    );
    const volumes = useAppSelector(selectCurrentProjectVolumes);
    const { executeTransformation, transformationStatus } = useTransformationManager();

    const handleCancelOnClick = () => {
        dispatch(setShouldShowPublishPanel(false));
        dispatch(setShouldShowProjectPanel(true));
    };

    const handleModelExportNameOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(setModelExportName(event.target.value));
    };

    const handlePublishOnClick = async () => {
        try {
            await executeTransformation(
                GtmMeshTransformationAction.ParameterizeVolumes,
                ShouldRenderUpdatedObjects.No,
                ShouldRunDetectorsOnUpdatedObjects.No,
                [aggregateGeometryObject!],
                {
                    name: `${currentProjectName}/${currentModel!.name}/${modelExportName}`,
                    distanceUnit: currentModel!.settings!.units,
                },
                {
                    createdObjectsHandler: (createdObjects) => {
                        dispatch(
                            addOrReplaceParameterizedGeometryEntryByName(
                                decorateNewObject(
                                    createdObjects[0],
                                    modelExportName,
                                    DESIGN_GEOMETRY_SCHEMA,
                                    true,
                                ),
                            ),
                        );
                    },
                },
            );

            dispatch(setShouldShowPublishPanel(false));
            dispatch(setShouldShowProjectPanel(true));
        } catch (e) {
            console.error('Parameterization failed', e);
        }
    };

    return (
        <Box sx={sx}>
            <WDSThemeProvider themeMode={appTheme}>
                <Stack spacing={2}>
                    <Paper sx={{ width: DEFAULT_PANEL_WIDTH }} elevation={4}>
                        <Stack
                            p={2}
                            direction="row"
                            sx={{ justifyContent: 'space-between', alignItems: 'center' }}
                        >
                            <Stack>
                                <Typography>{PUBLISH_PANEL_TITLE}</Typography>
                                <Typography color="secondary" variant="caption">
                                    {workspaceName}
                                </Typography>
                            </Stack>
                            <Tooltip onClick={handleCancelOnClick} title={CANCEL_LABEL}>
                                <IconButton size="small">
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                        <Divider />
                        <Stack p={2}>
                            <Typography
                                variant="body2"
                                color="secondary"
                                sx={{ textTransform: 'uppercase' }}
                            >
                                {MODEL_SECTION_TITLE}
                            </Typography>
                            <TextField
                                sx={(theme) => ({
                                    flexGrow: 1,
                                    marginTop: theme.spacing(2),
                                })}
                                size="small"
                                variant="outlined"
                                value={modelExportName}
                                onChange={handleModelExportNameOnChange}
                                InputProps={{ sx: { fontSize: '12px' } }}
                            />
                            <Box
                                sx={(theme) => ({
                                    maxHeight: DEFAULT_LIST_MAX_HEIGHT_PX,
                                    overflowY: 'auto',
                                    borderRadius: 0.5,
                                    border: 1,
                                    borderColor: 'divider',
                                    marginTop: theme.spacing(2),
                                })}
                            >
                                <List dense disablePadding>
                                    {volumes.map((volume, index) => (
                                        <ListItem
                                            key={volume.id}
                                            divider={index !== volumes.length - 1}
                                        >
                                            <ListItemText primary={volume.name} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        </Stack>
                        <Divider />
                        <Stack direction="row" p={2} spacing={2}>
                            <Button
                                sx={{ flex: 1 }}
                                size="small"
                                variant="outlined"
                                onClick={handleCancelOnClick}
                            >
                                {CANCEL_LABEL}
                            </Button>
                            <Button
                                sx={{ flex: 1 }}
                                size="small"
                                variant="contained"
                                onClick={handlePublishOnClick}
                            >
                                {PUBLISH_LABEL}
                            </Button>
                        </Stack>
                    </Paper>
                </Stack>
                <TransformationProgressModal
                    transformationStatus={transformationStatus}
                    transformationTitles={titlesByStatus}
                    transformationPercentages={percentagesByStatus}
                />
            </WDSThemeProvider>
        </Box>
    );
};

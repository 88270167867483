export interface ProjectPanelState {
    objectsPanelTabSelection: ObjectsTabSelection;
    isSearchingWorkspace: boolean;
    workspaceSearchTerm: string;
    selectedWorkspaceObjectIds: string[];
    shouldMinimizeProjectPanel: boolean;
    shouldOpenNoProjectDialog: boolean;
    shouldShowProjectPanel: boolean;
    openUploadObjectsDialog: boolean;
    boundaryCreationState: BoundaryCreationState;
}

export const enum ObjectsTabSelection {
    ModelObjects,
    WorkSpaceObjects,
}

export const enum BoundaryCreationState {
    None,
    DrawRectangle,
    EnterProperties,
}

export function isCreatingBoundary(state: BoundaryCreationState) {
    return state !== BoundaryCreationState.None;
}

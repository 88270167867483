import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

import { GtmMeshDetectorAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { IssueInfo } from 'src/store/issues/issuesSlice.types';
import {
    anyIssueIsStillLoading,
    selectDoesCurrentModelSelectedObjectHaveIssues,
    selectIssueDataForCurrentModelSelectedObject,
} from 'src/store/issues/selectors';
import {
    anAnalyticalModelIsSelected,
    selectCurrentModelSelectedObject,
} from 'src/store/project/selectors';
import { useAppSelector } from 'src/store/store';
import { IssuesAccordion } from 'src/visualization/SettingsPanel/components/ObjectSettingsPanel/components/IssuesTab/IssuesAccordion';
import { IssuesLoading } from 'src/visualization/SettingsPanel/components/ObjectSettingsPanel/components/IssuesTab/IssuesLoading';
import {
    CREATE_ANALYTICAL_MODEL_MESSAGE,
    issueKeyToHelperInfoMap,
    ISSUES_DETECTION_SETTINGS_MESSAGE,
    NO_ISSUES_MESSAGE,
} from 'src/visualization/SettingsPanel/components/ObjectSettingsPanel/components/IssuesTab/IssuesTab.constants';

export const IssuesTab = () => {
    const issues = useAppSelector(selectIssueDataForCurrentModelSelectedObject);
    const currentModelSelectedObject = useAppSelector(selectCurrentModelSelectedObject);
    const applyTrace = useTrace('issues-tab');
    const [expanded, setExpanded] = useState<string>('');
    const areIssuesLoading = useAppSelector(
        anyIssueIsStillLoading(currentModelSelectedObject?.id ?? ''),
    );
    const analyticalModelSelected = useAppSelector(anAnalyticalModelIsSelected);
    const doesSelectedObjectHaveIssues = useAppSelector(
        selectDoesCurrentModelSelectedObjectHaveIssues,
    );

    const createOnChangeHandler = (issueKey: string) => (_: unknown, newExpanded: boolean) => {
        setExpanded(newExpanded ? issueKey : '');
    };

    if (!analyticalModelSelected) {
        return (
            <Box p={2}>
                <Typography variant="body2">{CREATE_ANALYTICAL_MODEL_MESSAGE}</Typography>
            </Box>
        );
    }

    if (!issues || !currentModelSelectedObject) {
        return null;
    }

    if (areIssuesLoading) {
        return <IssuesLoading />;
    }

    if (!doesSelectedObjectHaveIssues && !areIssuesLoading) {
        return (
            <Box p={2}>
                <Typography variant="body2">{NO_ISSUES_MESSAGE}</Typography>
            </Box>
        );
    }

    return (
        <>
            <Stack automation-id={applyTrace('root-stack')}>
                <Stack>
                    {Object.entries(issues).map(([issueKey, issueInfo]: [string, IssueInfo]) => {
                        if (!issueInfo.data || issueInfo.data.length === 0) {
                            return null;
                        }
                        const helperInfo = issueKeyToHelperInfoMap.get(issueKey);
                        return (
                            <IssuesAccordion
                                expanded={expanded === issueKey}
                                onChange={createOnChangeHandler(issueKey)}
                                key={issueKey}
                                action={issueKey as GtmMeshDetectorAction}
                                objectId={currentModelSelectedObject.id}
                                issueInfo={issueInfo}
                                issueHelperInfo={helperInfo}
                            />
                        );
                    })}
                </Stack>
            </Stack>
            <Stack p={2}>
                <Typography variant="body2">{ISSUES_DETECTION_SETTINGS_MESSAGE}</Typography>
            </Stack>
        </>
    );
};

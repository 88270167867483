import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const BoundaryPointer = (props: SvgIconProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
        <path
            d="M7 18.5L4 13.5L4.73422 12.7658C5.45483 12.0452 6.60204 11.9816 7.39782 12.6183L8.5 13.5V4.5C8.5 4.5 8.5 3 10 3C11.5 3 11.5 4.5 11.5 4.5V10H12.6667C12.883 10 13.0936 10.0702 13.2667 10.2L16.4688 12.6016C16.7907 12.843 16.9365 13.2538 16.839 13.6442L16.5 15"
            stroke="currentColor"
            strokeWidth="2"
            fill="none"
        />
        <path
            d="M8.8286 20.3353L13.309 17.3929L14.6828 19.8862L19.6721 16.7418"
            stroke="currentColor"
            strokeWidth="2"
            fill="none"
        />
    </SvgIcon>
);

import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import RedoIcon from '@mui/icons-material/Redo';
import UndoIcon from '@mui/icons-material/Undo';
import IconButton from '@mui/material/IconButton/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { useHistoryManager } from 'src/hooks/history/useHistoryManager';
import {
    selectIsSyncingProject,
    selectLastRedoEntry,
    selectLastUndoEntry,
} from 'src/store/project/selectors';
import { useAppSelector } from 'src/store/store';

export const UndoRedoActions = () => {
    const applyTrace = useTrace('undo-redo-actions');
    const { undoOperation, redoOperation } = useHistoryManager();
    const lastUndoEntry = useAppSelector(selectLastUndoEntry);
    const lastRedoEntry = useAppSelector(selectLastRedoEntry);
    const isSyncingProject = useAppSelector(selectIsSyncingProject);

    return (
        <>
            <Tooltip
                title={
                    lastUndoEntry?.operation.description &&
                    `Undo: ${lastUndoEntry.operation.description}`
                }
            >
                {/* span here since tooltip doesn't like disabled buttons */}
                <span>
                    <IconButton
                        automation-id={applyTrace('undo-button')}
                        sx={{ margin: 0.5, padding: 0.5 }}
                        size="small"
                        onClick={undoOperation}
                        disabled={!lastUndoEntry || isSyncingProject}
                    >
                        <UndoIcon fontSize="small" />
                    </IconButton>
                </span>
            </Tooltip>
            <Tooltip
                title={
                    lastRedoEntry?.operation.description &&
                    `Redo: ${lastRedoEntry.operation.description}`
                }
            >
                <span>
                    <IconButton
                        automation-id={applyTrace('redo-button')}
                        sx={{ margin: 0.5, padding: 0.5 }}
                        size="small"
                        onClick={redoOperation}
                        disabled={!lastRedoEntry || isSyncingProject}
                    >
                        <RedoIcon fontSize="small" />
                    </IconButton>
                </span>
            </Tooltip>
        </>
    );
};

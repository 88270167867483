import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

export interface PercentProgressProps {
    /**
     * The percentage of the progress bar. Value between 0 and 100.
     */
    percent: number;

    /**
     * Optional flag to show the percentage text next to the progress bar.
     */
    showPercentText?: boolean;
}

/**
 * PercentProgress component displays a linear progress bar with an optional percentage text.
 * This in the style of our design.
 *
 * Note: The progress only changes if the percent prop changes!
 *
 * @component
 * @example
 * // Example usage:
 * <PercentProgress percent={75} showPercentText />
 *
 * @param {Readonly<PercentProgressProps>} props - The props for the PercentProgress component.
 * @param {number} props.percent - The percentage of the progress bar. Value between 0 and 100.
 * @param {boolean} [props.showPercentText] - Optional flag to show the percentage text next to the progress bar.
 *
 * @returns {JSX.Element} The rendered PercentProgress component.
 */
export const PercentProgress = ({ percent, showPercentText }: Readonly<PercentProgressProps>) => {
    const applyTrace = useTrace('percent-progress');
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" value={percent} color="primary" />
            </Box>
            {showPercentText && (
                <Box sx={{ minWidth: 35 }}>
                    <Typography
                        variant="body2"
                        automation-id={applyTrace('text')}
                        color="textPrimary"
                    >
                        {`${Math.round(percent)}%`}
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';

/**
 * Display the project name and don't allow rename/delete/selection
 */
export function StaticProjectName() {
    const { projectName } = useParams();
    return (
        <Box p={2}>
            <Typography variant="body1" color="textPrimary">
                {projectName}
            </Typography>
        </Box>
    );
}

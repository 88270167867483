import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { HUB_PATH, PROJECT_PATH, WORKSPACE_PATH } from 'src/constants';

export const useNavigateToProject = () => {
    const navigate = useNavigate();
    const { orgUuid, workspaceUuid, hubCode } = useParams();

    const navigateToProjectURL = useCallback(
        (navigateProjectName: string | undefined) => {
            const projectURL = navigateProjectName ? `${PROJECT_PATH}/${navigateProjectName}` : '';
            navigate(
                `/${orgUuid}${HUB_PATH}/${hubCode}${WORKSPACE_PATH}/${workspaceUuid}${projectURL}`,
            );
        },
        [orgUuid, hubCode, workspaceUuid, navigate],
    );

    return { navigateToProjectURL };
};

import { useLazyListGtmProjectFiles } from 'src/hooks/evoContext/useLazyListGtmProjectFiles';
import { useLazyListTriangleMeshes } from 'src/hooks/evoContext/useLazyListTriangleMeshes';
import { setProjectFiles, setUserTriangleMeshes } from 'src/store/evo/evoSlice';
import { useAppDispatch } from 'src/store/store';
import { INTERNAL_OBJECT_FOLDER } from 'src/strings';
import { ObjectId, VersionId, GeoscienceObject } from 'src/types/core.types';
import { fileNameExtensionRemover } from 'src/utils';

export function useEvoContextManager() {
    const dispatch = useAppDispatch();
    const [ListGtmProjectFilesTrigger] = useLazyListGtmProjectFiles();
    const [ListTriangleMeshesTrigger] = useLazyListTriangleMeshes();

    async function loadWorkspaceTriangleMeshes() {
        const triangleMeshes = (await ListTriangleMeshesTrigger())
            ?.filter(({ path }) => !path.includes(INTERNAL_OBJECT_FOLDER))
            .map(
                ({ object_id, version_id, name, schema }): GeoscienceObject => ({
                    id: object_id as ObjectId,
                    version: version_id as VersionId,
                    name,
                    schema,
                    visible: true,
                }),
            );

        dispatch(setUserTriangleMeshes(triangleMeshes));

        return triangleMeshes;
    }

    async function loadProjectFiles() {
        const files = (await ListGtmProjectFilesTrigger()) ?? [];
        const gtmProjectFiles =
            [...files]
                .sort((file1, file2) => Date.parse(file2.created_at) - Date.parse(file1.created_at))
                .map((file) => fileNameExtensionRemover(file.name)) ?? [];

        dispatch(setProjectFiles(gtmProjectFiles));

        return gtmProjectFiles;
    }

    return {
        loadWorkspaceTriangleMeshes,
        loadProjectFiles,
    };
}

import { assert } from './gtmAssert';

// Clamps value to [min, max]. Will swap min and max if they are in the wrong order.
export function clamp(value: number, minIn: number, maxIn: number): number {
    let [min, max] = [minIn, maxIn];
    if (min > max) {
        [min, max] = [max, min];
    }
    return Math.min(Math.max(value, min), max);
}

export function stringRepresentsFiniteNumber(text: string): boolean {
    // Not empty, and valid finite value
    return !/^\s*$/.test(text) && Number.isFinite(Number(text));
}

export function stringAsFiniteNumber(text: string): number {
    assert(
        stringRepresentsFiniteNumber(text),
        `Expected a string that represents a finite number, but received '${text}' instead.`,
    );
    return Number(text);
}

export function numberAsMaybeExponentialString(value: number): string {
    const DISPLAY_AS_EXPONENTIAL_BELOW_ABS = 1e-4;
    const DISPLAY_AS_EXPONENTIAL_FROM_AND_ABOVE_ABS = 1e5;

    // Avoid that value.toExponential() throws if value === null.
    if (value === null) {
        return 'NaN';
    }

    if (
        Math.abs(value) < DISPLAY_AS_EXPONENTIAL_BELOW_ABS ||
        Math.abs(value) >= DISPLAY_AS_EXPONENTIAL_FROM_AND_ABOVE_ABS
    ) {
        return value.toExponential();
    }

    return value.toString();
}

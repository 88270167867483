import { useParams } from 'react-router-dom';

import { useLazyGetFileByPathQuery } from 'src/apiClients/file/enhancedFileMiddleware';
import { GtmProject } from 'src/gtmProject/Project.types';
import { PROJECT_EXTENSION } from 'src/strings';
import { VersionId } from 'src/types/core.types';

export function useLazyFetchGtmProjectFile() {
    const [FetchFileTrigger] = useLazyGetFileByPathQuery();
    const { orgUuid, workspaceUuid } = useParams();

    async function FetchGtmProjectFileTrigger(
        file: string,
        versionId?: string,
    ): Promise<[project: GtmProject, versionId: VersionId] | undefined> {
        const { data } = await FetchFileTrigger({
            organisationId: orgUuid!,
            workspaceId: workspaceUuid!,
            filePath: `${file}.${PROJECT_EXTENSION}`,
            versionId,
        });

        if (data) {
            const projectResponse = await fetch(data.download);
            const project = await projectResponse.json();
            return [project as GtmProject, data.version_id as VersionId];
        }

        return undefined;
    }

    return [FetchGtmProjectFileTrigger];
}

import { generateEntity } from '@local/webviz/dist/context/snapshots/base';
import { UpdateSnapshot, Vector3, Snapshot } from '@local/webviz/dist/types/xyz';
import { ElementClass, ViewClass } from '@local/webviz/dist/xyz';

export interface BoundingBox {
    xMin: number;
    xMax: number;
    yMin: number;
    yMax: number;
    zMin: number;
    zMax: number;
}

export function computeBoundingBoxFromCenter(
    position: Vector3 | Float32Array,
    offset: number,
): BoundingBox {
    return {
        xMin: position[0] - offset,
        xMax: position[0] + offset,
        yMin: position[1] - offset,
        yMax: position[1] + offset,
        zMin: position[2] - offset,
        zMax: position[2] + offset,
    };
}

export function computeBoundingBoxVertices(box: BoundingBox): Float32Array {
    const { xMin, xMax, yMin, yMax, zMin, zMax } = box;
    // prettier-ignore
    return Float32Array.from([
        xMin, yMin, zMin,   // 0
        xMax, yMin, zMin,   // 1
        xMax, yMax, zMin,   // 2
        xMin, yMax, zMin,   // 3
        xMin, yMin, zMax,   // 4
        xMax, yMin, zMax,   // 5
        xMax, yMax, zMax,   // 6
        xMin, yMax, zMax,   // 7
    ]);
}

export function getGlobalBoundingBox(boundingBoxes: BoundingBox[]): BoundingBox | undefined {
    if (boundingBoxes.length === 0) {
        return undefined;
    }

    const initialBoundingBox: BoundingBox = {
        xMin: Infinity,
        xMax: -Infinity,
        yMin: Infinity,
        yMax: -Infinity,
        zMin: Infinity,
        zMax: -Infinity,
    };

    const globalBoundingBox = boundingBoxes.reduce(
        (acc, bbox) => ({
            xMin: Math.min(acc.xMin, bbox.xMin),
            xMax: Math.max(acc.xMax, bbox.xMax),
            yMin: Math.min(acc.yMin, bbox.yMin),
            yMax: Math.max(acc.yMax, bbox.yMax),
            zMin: Math.min(acc.zMin, bbox.zMin),
            zMax: Math.max(acc.zMax, bbox.zMax),
        }),
        initialBoundingBox,
    );

    return globalBoundingBox;
}

export function getBoundingBoxSnapshot(box: BoundingBox, label: string): UpdateSnapshot {
    const elementId = `bounding-box-${label}`;
    const viewId = label;

    return {
        [elementId]: {
            id: elementId,
            __class__: ElementClass.Surface,
            vertices: computeBoundingBoxVertices(box),
            // prettier-ignore
            triangles: [
                0, 2, 1, 0, 3, 2,   // xyMin
                4, 5, 6, 4, 6, 7,   // xyMax
                0, 1, 5, 0, 5, 4,   // xzMin
                2, 7, 6, 2, 3, 7,   // xzMax
                3, 4, 7, 3, 0, 4,   // yzMin
                1, 2, 6, 1, 6, 5,   // yzMax
            ],
        },

        [viewId]: generateEntity(ViewClass.Surface, {
            id: viewId,
            element: elementId,
            color: [39, 242, 96],
            wireframe: false,
            showFaces: true,
            opacity: 0.2,
        }),
    };
}

/**
 * Gets the bounding box of an xyz plot from a snapshot
 * @param xyzSnapshot The snapshot to get the bounding box from
 * @returns The bounding box of the plot if it exists
 */
export function getPlotBoundingBox(xyzSnapshot: Snapshot): BoundingBox | undefined {
    const { boundingBox } = xyzSnapshot.plot as any; // boundingBox exists on `InternalPlotState`, which is not an exported type.
    if (boundingBox) {
        return {
            xMin: boundingBox.min[0],
            xMax: boundingBox.max[0],
            yMin: boundingBox.min[1],
            yMax: boundingBox.max[1],
            zMin: boundingBox.min[2],
            zMax: boundingBox.max[2],
        };
    }
    return undefined;
}

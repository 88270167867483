import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import Button from '@mui/material/Button/Button';

import { GtmMeshTransformationAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { useVolumesManager } from 'src/hooks/modelling/useVolumesManager';
import {
    ShouldRenderUpdatedObjects,
    ShouldRunDetectorsOnUpdatedObjects,
    TransformationStatus,
    useTransformationManager,
} from 'src/hooks/transformation/useTransformationManager';
import {
    selectCurrentAnalyticalModelSettings,
    selectCurrentModelSelectedObject,
} from 'src/store/project/selectors';
import { useAppSelector } from 'src/store/store';
import { assert } from 'src/utils/gtmAssert';
import {
    FIX_DEGENERATE_TRIANGLES_LABEL,
    FIXING_DEGENERATE_TRIANGLES_FAILURE_MESSAGE,
    FIXING_DEGENERATE_TRIANGLES_MESSAGE,
    FIXING_DEGENERATE_TRIANGLES_SUCCESS_MESSAGE,
} from 'src/visualization/SettingsPanel/components/ObjectSettingsPanel/components/IssuesTab/TransformationAction/TransformationAction.constants';
import { TransformationProgressModal } from 'src/visualization/TransformationProgressModal/TransformationProgressModal';

const titlesByStatus = new Map<TransformationStatus, string>([
    [TransformationStatus.Transforming, FIXING_DEGENERATE_TRIANGLES_MESSAGE],
    [TransformationStatus.Uploading, FIXING_DEGENERATE_TRIANGLES_MESSAGE],
    [TransformationStatus.Complete, FIXING_DEGENERATE_TRIANGLES_SUCCESS_MESSAGE],
    [TransformationStatus.Failed, FIXING_DEGENERATE_TRIANGLES_FAILURE_MESSAGE],
]);
const percentagesByStatus = new Map<TransformationStatus, number>([
    [TransformationStatus.Transforming, 0],
    [TransformationStatus.Uploading, 60],
    [TransformationStatus.Complete, 100],
    [TransformationStatus.Failed, 100],
]);

export const FixDegenerateTriangles = () => {
    const applyTrace = useTrace('fix-degenerate-triangles');
    const { executeTransformation, transformationStatus } = useTransformationManager();
    const selectedObject = useAppSelector(selectCurrentModelSelectedObject);
    assert(
        selectedObject !== undefined,
        'Degenerate triangle fixer does not know to which object it applies.',
    );
    const currentModelSettings = useAppSelector(selectCurrentAnalyticalModelSettings);
    const { resetVolumesIfObjectIsAggregate } = useVolumesManager();

    const handleTransformation = () => {
        if (!currentModelSettings || !selectedObject) {
            return;
        }

        const {
            degenerateTriangleSettings: {
                capMinAngleDegrees,
                needleCollapseLength,
                needleThresholdRatio,
            },
        } = currentModelSettings;

        executeTransformation(
            GtmMeshTransformationAction.RemoveDegenerateTris,
            ShouldRenderUpdatedObjects.Yes,
            ShouldRunDetectorsOnUpdatedObjects.Yes,
            [selectedObject],
            {
                needleThresholdRatio,
                capMinAngleDegrees,
                needleCollapseLength,
            },
            {
                handleAdditionalSideEffects: () => {
                    resetVolumesIfObjectIsAggregate(selectedObject);
                },
            },
        );
    };

    return (
        <>
            <Button
                automation-id={applyTrace('button')}
                size="small"
                sx={{ width: '100%' }}
                variant="contained"
                onClick={handleTransformation}
            >
                {FIX_DEGENERATE_TRIANGLES_LABEL}
            </Button>
            <TransformationProgressModal
                transformationStatus={transformationStatus}
                transformationTitles={titlesByStatus}
                transformationPercentages={percentagesByStatus}
            />
        </>
    );
};

export const DETECTION_SETTINGS_PANEL_TITLE = 'Detection Settings';
export const getDetectionSettingsUpdateDescription = (modelName: string) =>
    `Update detection settings of model "${modelName}"`;

// Holes
export const HOLE_SETTINGS_HEADER = 'Holes';
export const HOLE_SIZE_RATIO = 'Hole size ratio';
export const HOLE_SIZE_RATIO_INFO =
    'The ratio between the bounding box diagional of a boundary loop and the bounding box diagonal of the surface below which we consider the boundary loop to represent a hole in the surface.';

// Degenerate triangles
export const NEEDLE_THRESHOLD_RATIO = 'Needle threshold ratio';
export const NEEDLE_THRESHOLD_RATIO_INFO =
    'Threshold ratio of the shortest edge of a triangle to the longest edge at which a triangle is considered a needle. Needles are considered to be degenerate.';
export const NEEDLE_COLLAPSE_LENGTH = 'Needle collapse length';
export const NEEDLE_COLLAPSE_LENGTH_INFO =
    'Maximum edge length that will be collapsed via this operation. If value is zero, this parameter has no effect.';
export const CAP_MINIMUM_ANGLE = 'Cap minimum angle';
export const CAP_MINIMUM_ANGLE_INFO =
    'Threshold of a minimum angle, in degrees, such that if a triangle has an angle greater than this value, it is considered a cap. Caps are considered to be degenerate. The threshold is angle between `90` and `180` degrees.';
export const DEGENERATE_TRIANGLE_SETTINGS_HEADER = 'Degenerate triangles';

// Self-intersections
export const SELF_INTERSECTION_SETTINGS_HEADER = 'Self-intersections';
export const TOLERANCE = 'Tolerance';
export const TOLERANCE_INFO =
    'The absolute distance between unconnected triangles of a single surface below which we consider the surface to self-intersect.';

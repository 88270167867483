import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FormattedDate } from 'react-intl';
import { useParams, useNavigate } from 'react-router-dom';

import { HUB_PATH, WORKSPACE_PATH } from 'src/constants';
import { setWorkspaceName } from 'src/store/evo/evoSlice';
import { useAppDispatch } from 'src/store/store';

import { useStyles } from './SelectionTables.styles';
import { WorkspaceSelectionTableProps } from './SelectionTables.types';

export function WorkspaceSelectionTable({ data, onClose }: WorkspaceSelectionTableProps) {
    const dispatch = useAppDispatch();
    const { classes } = useStyles();
    const { orgUuid, workspaceUuid, hubCode } = useParams();
    const navigate = useNavigate();

    const rows =
        data?.results?.map((workspace) => ({
            name: workspace.name,
            description: workspace.description,
            permission: workspace.current_user_role,
            updated_at: workspace.updated_at,
            workspaceId: workspace.id,
        })) || [];

    const createWorkspaceOnClickHandler =
        ({ name, workspaceId }: (typeof rows)[number]) =>
        () => {
            if (workspaceUuid === workspaceId) {
                return;
            }

            dispatch(setWorkspaceName(name));
            navigate(`/${orgUuid}${HUB_PATH}/${hubCode}${WORKSPACE_PATH}/${workspaceId}`);
            onClose();
        };

    return (
        <Grid container direction="column" alignContent="space-around">
            <Grid item style={{ position: 'relative' }}>
                <TableContainer>
                    <Table
                        size="small"
                        className={classes.historyTable}
                        stickyHeader
                        aria-label="sticky table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="right">Description</TableCell>
                                <TableCell align="right">Your Permission</TableCell>
                                <TableCell align="right">Last Updated</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows &&
                                rows.map((row) => (
                                    <TableRow key={row.name} hover>
                                        <TableCell component="th" scope="row">
                                            {/* Link is using a button component below. The linter rule is incorrectly flagging it */}
                                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                            <Link
                                                underline="none"
                                                component="button"
                                                variant="body2"
                                                onClick={createWorkspaceOnClickHandler(row)}
                                            >
                                                {row.name}
                                            </Link>
                                        </TableCell>
                                        <TableCell align="right">{row.description}</TableCell>
                                        <TableCell align="right">{row.permission}</TableCell>
                                        <TableCell align="right">
                                            <span style={{ paddingRight: '8px' }}>
                                                <FormattedDate
                                                    value={row.updated_at}
                                                    month="short"
                                                    year="numeric"
                                                    day="2-digit"
                                                />
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
}

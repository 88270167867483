import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import { useBaseXyz } from '@local/webviz/dist/context/hooks/useBaseXyz';
import { createSnapshotToUpdateCamera } from '@local/webviz/dist/context/snapshots';
import { CameraMode } from '@local/webviz/dist/xyz';
import PanIcon from '@mui/icons-material/PanToolOutlined';
import RotateIcon from '@mui/icons-material/ThreeSixtyOutlined';
import ZoomIcon from '@mui/icons-material/ZoomInOutlined';
import IconButton from '@mui/material/IconButton';
import { Theme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { useEffect, useState } from 'react';

import {
    PAN_LABEL,
    ROTATE_LABEL,
    ZOOM_LABEL,
} from 'src/visualization/Toolbar/CameraTools.constants';

const iconButtonStyle = (theme: Theme) => ({
    padding: theme.spacing(0.5),
    margin: theme.spacing(0.5),
});

export const CameraTools = () => {
    const applyTrace = useTrace('camera-tools');
    const { addListener } = useBaseXyz();
    const [selectedTool, setSelectedTool] = useState(CameraMode.Rotate);
    const { setXyzStateDirectly } = useBaseXyz();

    useEffect(() => {
        const removeListener = addListener('camera', 'mode', (mode: CameraMode) =>
            setSelectedTool(mode),
        );
        return () => removeListener();
    }, []);

    const handleSelectTool = (mode: CameraMode) => () => {
        const snapshot = createSnapshotToUpdateCamera(mode);
        setXyzStateDirectly(snapshot);
        setSelectedTool(mode);
    };

    return (
        <>
            <Tooltip title={ROTATE_LABEL}>
                <IconButton
                    automation-id={applyTrace('rotate-button')}
                    color={selectedTool === CameraMode.Rotate ? 'primary' : 'default'}
                    sx={iconButtonStyle}
                    size="small"
                    onClick={handleSelectTool(CameraMode.Rotate)}
                >
                    <RotateIcon fontSize="small" />
                </IconButton>
            </Tooltip>
            <Tooltip title={PAN_LABEL}>
                <IconButton
                    automation-id={applyTrace('pan-button')}
                    color={selectedTool === CameraMode.Pan ? 'primary' : 'default'}
                    sx={iconButtonStyle}
                    size="small"
                    onClick={handleSelectTool(CameraMode.Pan)}
                >
                    <PanIcon fontSize="small" />
                </IconButton>
            </Tooltip>
            <Tooltip title={ZOOM_LABEL}>
                <IconButton
                    automation-id={applyTrace('zoom-button')}
                    color={selectedTool === CameraMode.Zoom ? 'primary' : 'default'}
                    sx={iconButtonStyle}
                    size="small"
                    onClick={handleSelectTool(CameraMode.Zoom)}
                >
                    <ZoomIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </>
    );
};

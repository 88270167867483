import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ShowIcon from '@mui/icons-material/RemoveRedEye';
import HideIcon from '@mui/icons-material/VisibilityOff';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton/IconButton';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useState, MouseEvent, SyntheticEvent } from 'react';

import {
    GtmMeshDetectorAction,
    GtmMeshTransformationAction,
} from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { HelperAccordion } from 'src/components/HelperAccordion/HelperAccordion';
import { IssueInfo } from 'src/store/issues/issuesSlice.types';
import { DEFAULT_LIST_MAX_HEIGHT_PX } from 'src/styles';
import { countIssues } from 'src/utils/countIssues';
import {
    detectorActionToDefectString,
    detectorActionToTransformationAction,
} from 'src/utils/typeTransformations';
import { AccordionForDefects } from 'src/visualization/SettingsPanel/components/ObjectSettingsPanel/components/IssuesTab/AccordionForDefects';
import { IssueRenderer } from 'src/visualization/SettingsPanel/components/ObjectSettingsPanel/components/IssuesTab/IssueRenderer';
import { TransformationAction } from 'src/visualization/SettingsPanel/components/ObjectSettingsPanel/components/IssuesTab/TransformationAction';

export function IssuesAccordion({
    expanded,
    onChange,
    action,
    objectId,
    issueInfo,
    issueHelperInfo,
}: {
    expanded: boolean;
    onChange: (event: SyntheticEvent, expanded: boolean) => void;
    action: GtmMeshDetectorAction;
    objectId: string;
    issueInfo: IssueInfo;
    issueHelperInfo?: [issueHelperTitle: string, issueHelperExplanation: string];
}) {
    const actionDisplayString = detectorActionToDefectString(action);
    const idString = `${actionDisplayString}-list`;
    const [areIssuesVisible, setAreIssuesVisible] = useState(true);
    const transformationAction = detectorActionToTransformationAction(action);

    const handleToggleVisibility = (event: MouseEvent) => {
        event.stopPropagation();
        setAreIssuesVisible((currentVisibility) => !currentVisibility);
    };

    return (
        <>
            <Accordion
                expanded={expanded}
                onChange={onChange}
                sx={{ bgcolor: 'inherit' }}
                elevation={0}
                disableGutters
                TransitionProps={{ unmountOnExit: true }}
            >
                <AccordionSummary
                    sx={{
                        '.MuiAccordionSummary-content': {
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        },
                    }}
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Stack direction="row" sx={{ alignItems: 'center' }}>
                        <IconButton size="small" onClick={handleToggleVisibility}>
                            {areIssuesVisible ? <ShowIcon /> : <HideIcon />}
                        </IconButton>
                        <Typography
                            sx={(theme) => ({
                                textTransform: 'uppercase',
                                marginLeft: theme.spacing(1),
                            })}
                            variant="body2"
                            color="secondary"
                        >
                            {`${actionDisplayString}s`}
                        </Typography>
                    </Stack>
                    <Avatar
                        sx={(theme) => ({
                            bgcolor: theme.palette.warning.light,
                            height: '20px',
                            width: '20px',
                            color: theme.palette.common.black,
                            marginRight: theme.spacing(1),
                        })}
                    >
                        <Typography variant="caption">
                            {countIssues(action, issueInfo.data)}
                        </Typography>
                    </Avatar>
                </AccordionSummary>
                <AccordionDetails sx={(theme) => ({ p: theme.spacing(0, 2, 2) })}>
                    {issueHelperInfo && (
                        <HelperAccordion
                            sx={{ mb: 2 }}
                            title={issueHelperInfo[0]}
                            detailedExplanation={issueHelperInfo[1]}
                        />
                    )}

                    <Box
                        sx={(theme) => ({
                            maxHeight: DEFAULT_LIST_MAX_HEIGHT_PX,
                            borderRadius: 0.5,
                            border: 1,
                            borderColor: 'divider',
                            marginBottom: theme.spacing(2),
                        })}
                    >
                        <List dense disablePadding>
                            <AccordionForDefects
                                idBase={idString}
                                action={action}
                                data={issueInfo.data}
                            />
                        </List>
                    </Box>
                    <TransformationAction
                        action={transformationAction as GtmMeshTransformationAction}
                    />
                </AccordionDetails>
            </Accordion>
            <Divider />
            {areIssuesVisible && (
                <IssueRenderer objectId={objectId} action={action} info={issueInfo} />
            )}
        </>
    );
}

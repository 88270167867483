export const MODEL_TITLE = 'Model';
export const BACKGROUND_LABEL = 'Background';
export const UNITS_LABEL = 'Units';
export const XYZ_AXIS_LABEL = 'XYZ Axis';
export const ON_LABEL = 'On';
export const OFF_LABEL = 'Off';
export const getBackgroundColorUpdateDescription = (modelName: string, color: string) =>
    `Change background color of model "${modelName}" to ${color}`;
export const getDistanceUnitsUpdateDescription = (modelName: string, distanceUnits: string) =>
    `Change distance units of model "${modelName}" to ${distanceUnits}`;
export const getXyzAxisUpdateDescription = (modelName: string, shouldShow: boolean) =>
    `${shouldShow ? 'Show' : 'hide'} XYZ axis in "${modelName}"`;

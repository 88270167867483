import {
    GtmMeshFillHoleParams,
    GtmMeshRemoveDegenerateTriangleParams,
    GtmMeshTransformationAction,
    GtmMeshTransformationParams,
    GtmRemeshParams,
} from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { GtmHistoryOperation, GtmModel } from 'src/gtmProject';
import { GeoscienceObject } from 'src/types/core.types';

const removeUnusedVerticesDescription = (modelName: string, objectName: string) =>
    `Remove unused vertices of object "${objectName}" in model "${modelName}"`;
const parameterizeVolumesDescription = (modelName: string, objectName: string) =>
    `Parameterize volumes for object "${objectName}" in model "${modelName}"`;
const addToAggregateGeomDescription = (modelName: string, objectName: string) =>
    `Add object "${objectName}" to aggregate geometry of model "${modelName}"`;
const separateVolumesDescription = (modelName: string) =>
    `Separate volumes of aggregate geometry in model "${modelName}"`;

const removeDegenerateTriangleDescription = (
    params: GtmMeshRemoveDegenerateTriangleParams,
    modelName: string,
    objectName: string,
): string =>
    `Remove degenerate triangles of object "${objectName}" in model "${modelName}" with cap minimum angle of ${params.capMinAngleDegrees} and needle threshold ratio of ${params.needleThresholdRatio}`;

const fillHoleDescription = (
    params: GtmMeshFillHoleParams,
    modelName: string,
    objectName: string,
): string =>
    `Fill hole of object "${objectName}" in model "${modelName}" with fill mode ${params.fillMode}`;

// TODO: may want to add more details depending on what we expose to the user, or summarize differently
const remeshDescription = (
    params: GtmRemeshParams,
    modelName: string,
    objectName: string,
): string =>
    `Remesh of object "${objectName}" in model "${modelName}" with patch angle tolerance of ${params.patchAngleTolerance} and max chordal error of ${params.maxChordalError}`;

/**
 * Summarize the history operation for a transformation action
 */
export function summarizeTransformationActionHistoryOperation(
    action: GtmMeshTransformationAction,
    params: GtmMeshTransformationParams,
    model: GtmModel,
    objects: GeoscienceObject[],
): GtmHistoryOperation {
    switch (action) {
        case GtmMeshTransformationAction.RemoveDegenerateTris: {
            return {
                description: removeDegenerateTriangleDescription(
                    params as GtmMeshRemoveDegenerateTriangleParams,
                    model.name as string,
                    objects[0].name,
                ),
            };
        }
        case GtmMeshTransformationAction.RemoveUnusedPoints:
            return {
                description: removeUnusedVerticesDescription(model.name as string, objects[0].name),
            };
        case GtmMeshTransformationAction.ParameterizeVolumes:
            return {
                description: parameterizeVolumesDescription(model.name as string, objects[0].name),
            };
        case GtmMeshTransformationAction.FillHoles:
            return {
                description: fillHoleDescription(
                    params as GtmMeshFillHoleParams,
                    model.name as string,
                    objects[0].name,
                ),
            };
        case GtmMeshTransformationAction.Remesh:
            return {
                description: remeshDescription(
                    params as GtmRemeshParams,
                    model.name as string,
                    objects[0].name,
                ),
            };
        case GtmMeshTransformationAction.AddToAggregateGeom:
            return {
                description: addToAggregateGeomDescription(model.name as string, objects[0].name),
            };
        case GtmMeshTransformationAction.SeparateVolumes:
            return {
                description: separateVolumesDescription(model.name as string),
            };
        default:
            return {
                description: `${action}`,
            };
    }
}

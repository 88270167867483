import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useConglomerateActionManager } from 'src/hooks/conglomerate/useConglomerateActionManager';
import { setSelectedModelIndex } from 'src/store/project/projectSlice';
import { useAppDispatch } from 'src/store/store';
import { setShouldOpenNoProjectDialog, setShouldShowProjectPanel } from 'src/store/ui/projectPanel';
import { useNavigateToProject } from 'src/visualization/ProjectPanel/components/useNavigateToProject';

export const UrlListener = () => {
    const dispatch = useAppDispatch();
    const { workspaceUuid, projectName: urlProjectName } = useParams();
    const { refreshVisualizationAndIssues, swapProject, swapWorkspace } =
        useConglomerateActionManager();
    const navigate = useNavigate();
    const { navigateToProjectURL } = useNavigateToProject();

    useEffect(() => {
        async function loadProjectDataOnNameChange(name: string) {
            const project = await swapProject(name);

            if (!project) {
                console.error(`Error loading project data for project ${name}`);
                navigate(-1);
                return;
            }

            if (project.models.length > 0) {
                // Using refreshVisualizationAndIssues instead of switchCurrentModel since the project data in the switchCurrentModel closure is stale
                dispatch(setSelectedModelIndex(0));
                refreshVisualizationAndIssues(project.models[0]);
            }
        }

        if (urlProjectName) {
            loadProjectDataOnNameChange(urlProjectName);
        }
    }, [urlProjectName]);

    useEffect(() => {
        async function swapWorkspaceOnUuidChange() {
            const projectFiles = await swapWorkspace();

            if (projectFiles.length === 0) {
                dispatch(setShouldOpenNoProjectDialog(true));
                dispatch(setShouldShowProjectPanel(false));
            } else if (!urlProjectName) {
                navigateToProjectURL(projectFiles[0]);
            }
        }

        if (workspaceUuid) {
            swapWorkspaceOnUuidChange();
        }
    }, [workspaceUuid]);

    return null;
};

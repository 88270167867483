export const BOUNDING_BOX_NO_BOUNDS_TITLE = 'New Analytical Model';
export const BOUNDING_BOX_TITLE = 'Model Details';
export const NAME_TITLE = 'Model Name';
export const BOUNDING_BOX_DEFAULT_NAME = BOUNDING_BOX_NO_BOUNDS_TITLE;
export const MIN_TITLE = 'Minimum';
export const MAX_TITLE = 'Maximum';
export const ACCEPT = 'Create';
export const ENCLOSE_ENTIRE_MODEL = 'Enclose Entire Model';
export const REDRAW_LABEL = 'Redraw';

export const ERROR_DUPLICATE_NAME = 'This model already exists.';
export const ERROR_MAX_MUST_GREATER_THAN_MIN = 'Maximum must be greater than minimum.';

export const START_ANALYTICAL_MODEL_CREATION_MESSAGE = `Creating analytical model...`;
export const ANALYTICAL_MODEL_CREATION_SUCCESS_MESSAGE = 'Model created';
export const ANALYTICAL_MODEL_CREATION_ERROR_TITLE = 'Creation failed';
export const ANALYTICAL_MODEL_CREATION_ERROR_MESSAGE =
    'Retry the operation or adjust your co-ordinates';

// Use a % padding to increase the z-min and z-max.
export const BOUNDING_BOX_PADDING = 0.01;

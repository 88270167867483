import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import Button from '@mui/material/Button';
import { useState } from 'react';

import { defaultAnalyticalModelSettings } from 'src/apiClients/gtmCompute/gtmComputeApi';
import { FixTwoIcon } from 'src/assets/FixTwoIcon';
import {
    TransformationStatus,
    useTransformationManager,
} from 'src/hooks/transformation/useTransformationManager';
import {
    selectCurrentAnalyticalModelSettings,
    selectSelectedModelIndex,
} from 'src/store/project/selectors';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { setAllRemeshSettings } from 'src/store/ui/remeshSettings';
import { remeshSettingsFromAnalyticalModelSettings } from 'src/store/ui/remeshSettings/selectors';
import { selectedSceneObjects } from 'src/store/visualization/selectors';
import { REMESH_LABEL } from 'src/visualization/Toolbar/RemeshButton.constants';

import { TransformationProgressModal } from '../TransformationProgressModal/TransformationProgressModal';
import { RemeshSettingsDialog } from './RemeshSettingsDialog';
import {
    STATUS_COMPLETE,
    STATUS_FAILED,
    STATUS_TRANSFORMING,
} from './RemeshSettingsDialog.constants';

const titlesByStatus = new Map<TransformationStatus, string>([
    [TransformationStatus.Transforming, STATUS_TRANSFORMING],
    [TransformationStatus.Uploading, STATUS_TRANSFORMING],
    [TransformationStatus.Complete, STATUS_COMPLETE],
    [TransformationStatus.Failed, STATUS_FAILED],
]);
const percentagesByStatus = new Map<TransformationStatus, number>([
    [TransformationStatus.Transforming, 0],
    [TransformationStatus.Uploading, 85],
    [TransformationStatus.Complete, 100],
    [TransformationStatus.Failed, 100],
]);

export function RemeshButton() {
    const applyTrace = useTrace('remesh');
    const dispatch = useAppDispatch();
    const { executeTransformation, transformationStatus } = useTransformationManager();
    const selectedModelIndex = useAppSelector(selectSelectedModelIndex);
    const selectedObject = Object.values(useAppSelector(selectedSceneObjects))[0];
    const [showingRemeshSettings, setShowingRemeshSettings] = useState(false);
    const [anchorElRemeshSettings, setAnchorElRemeshSettings] = useState<null | HTMLElement>(null);
    const currentAnalyticalModelSettings =
        useAppSelector(selectCurrentAnalyticalModelSettings) ?? defaultAnalyticalModelSettings;

    const handleOpenSettings = (event: React.MouseEvent<HTMLButtonElement>) => {
        const remeshSettings = remeshSettingsFromAnalyticalModelSettings(
            currentAnalyticalModelSettings,
        );
        dispatch(setAllRemeshSettings(remeshSettings));
        setAnchorElRemeshSettings(event.currentTarget);
        setShowingRemeshSettings(!showingRemeshSettings);
    };

    const handleCloseSettings = () => {
        setShowingRemeshSettings(!showingRemeshSettings);
        setAnchorElRemeshSettings(null);
    };

    return (
        <>
            <Button
                sx={{ height: '20px' }}
                automation-id={applyTrace('open-dialog-button')}
                size="small"
                variant="text"
                color="primary"
                startIcon={<FixTwoIcon width={18} height={18} viewBox="0 0 18 18" />}
                disabled={!selectedObject}
                onClick={handleOpenSettings}
            >
                {REMESH_LABEL}
            </Button>
            {anchorElRemeshSettings && (
                <RemeshSettingsDialog
                    modelIndex={selectedModelIndex}
                    anchorEl={anchorElRemeshSettings}
                    executeTransformation={executeTransformation}
                    onClose={handleCloseSettings}
                />
            )}
            <TransformationProgressModal
                transformationStatus={transformationStatus}
                transformationTitles={titlesByStatus}
                transformationPercentages={percentagesByStatus}
            />
        </>
    );
}
